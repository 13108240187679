// Generated by Framer (295c121)

import { addFonts, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX, withOptimizedAppearEffect } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/n6ak1dmIx";
const MotionDivWithFXWithOptimizedAppearEffect = withOptimizedAppearEffect(withFX(motion.div));

const serializationHash = "framer-t7Sfl"

const variantClassNames = {M6RRX2t1V: "framer-v-13ivx7l"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 100, delay: 1, mass: 1, stiffness: 400, type: "spring"}

const animation = {opacity: 1, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition1, x: 0, y: 0}

const animation1 = {opacity: 0.001, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const transition2 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "M6RRX2t1V", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition2}><MotionDivWithFXWithOptimizedAppearEffect {...restProps} {...gestureHandlers} __framer__presenceAnimate={animation} __framer__presenceInitial={animation1} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(scopingClassNames, "framer-13ivx7l", className, classNames)} data-framer-appear-id={"13ivx7l"} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"M6RRX2t1V"} optimized ref={ref ?? ref1} style={{background: "linear-gradient(0deg, rgba(255, 255, 255, 0.09) 20%, rgba(255, 255, 255, 0.7) 158%)", borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40, boxShadow: "inset 0px 2px 6px 0px rgba(255, 255, 255, 0.4)", ...style}}><motion.div className={"framer-3gsz0z"} layoutDependency={layoutDependency} layoutId={"PSp2p1vcV"} style={{backgroundColor: "var(--token-4ba62d1a-4ba8-45fb-8c2d-7ee830b43cdb, rgb(123, 227, 199))", borderBottomLeftRadius: 84, borderBottomRightRadius: 84, borderTopLeftRadius: 84, borderTopRightRadius: 84}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-prw4x"} data-styles-preset={"n6ak1dmIx"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-0d217399-5502-4e36-ad35-aff6664c8307, rgb(255, 255, 255)))"}}>TRUSTED BY 1000+ STUDENTS</motion.p></React.Fragment>} className={"framer-1qsa52m"} data-framer-name={"AI assistent"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"xLXCTSBnd"} style={{"--extracted-r6o4lv": "var(--token-0d217399-5502-4e36-ad35-aff6664c8307, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></MotionDivWithFXWithOptimizedAppearEffect></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-t7Sfl.framer-jsstcq, .framer-t7Sfl .framer-jsstcq { display: block; }", ".framer-t7Sfl.framer-13ivx7l { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; padding: 6px 16px 6px 16px; position: relative; width: min-content; }", ".framer-t7Sfl .framer-3gsz0z { flex: none; height: 5px; overflow: hidden; position: relative; width: 5px; will-change: var(--framer-will-change-override, transform); }", ".framer-t7Sfl .framer-1qsa52m { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-t7Sfl.framer-13ivx7l { gap: 0px; } .framer-t7Sfl.framer-13ivx7l > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-t7Sfl.framer-13ivx7l > :first-child { margin-left: 0px; } .framer-t7Sfl.framer-13ivx7l > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 36
 * @framerIntrinsicWidth 254
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerTuNzVenq2: React.ComponentType<Props> = withCSS(Component, css, "framer-t7Sfl") as typeof Component;
export default FramerTuNzVenq2;

FramerTuNzVenq2.displayName = "Pre-Header Component 2";

FramerTuNzVenq2.defaultProps = {height: 36, width: 254};

addFonts(FramerTuNzVenq2, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})